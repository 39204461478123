import { Clue } from "./clue";
import { Row, RowState } from "./Row";
import { gameName, maxGuesses } from "./util";

export function About() {
  return (
    <div className="App-about">
      <p>
        {gameName} is a variation on the word game <i>Wordle</i>, which is a variation on the TV show <i>Lingo</i>.
      </p>
      <p>
        {gameName} is <i>unique</i> in many key ways.
      </p>
      <p>
	      Players can play multiple times per day. Players can give up and see the answer. Players can choose between 4-11 letter games.
      </p>
      <p>
        To play, you get {maxGuesses} tries to guess a target word.
        <br />
        After each guess, you get feedback clues.
      </p>
      <hr />
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Correct, letter: "w" },
          { clue: Clue.Absent, letter: "o" },
          { clue: Clue.Elsewhere, letter: "r" },
          { clue: Clue.Absent, letter: "d" },
          { clue: Clue.Correct, letter: "l" },
        ]}
      />
      <p>
      <b className={"green-bg"}>W</b> and <b className={"green-bg"}>L</b> are placed correctly.
      </p>
      <p>
        <b>O</b> and <b>D</b> are not in the target word at all.
      </p>
      <p>
        <b className={"yellow-bg"}>R</b> occurs <em>elsewhere</em> in the target
        word.
      </p>
      <p>
        All letters may occur more than once.
      </p>
      <hr />
      <p>
        Try changing <b>D</b> <b>R</b> <b>X</b> in the next guess.
      </p>
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Correct, letter: "w" },
          { clue: Clue.Correct, letter: "o" },
          { clue: Clue.Elsewhere, letter: "d" },
          { clue: Clue.Elsewhere, letter: "r" },
          { clue: Clue.Absent, letter: "x" },
        ]}
        annotation={"Close!"}
      />
      <Row
        rowState={RowState.LockedIn}
        wordLength={5}
        cluedLetters={[
          { clue: Clue.Correct, letter: "w" },
          { clue: Clue.Correct, letter: "o" },
          { clue: Clue.Correct, letter: "r" },
          { clue: Clue.Correct, letter: "d" },
          { clue: Clue.Correct, letter: "l" },
        ]}
        annotation={"Correct!"}
      />
      <p>
        To report issues,{" "}
        tweet{" "}
        <a href="https://twitter.com/countrymusicfy" target="_blank" rel="noopener noreferrer"><strong>@countrymusicfy</strong></a>
      </p>
      <div className="row buttons-container">
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://www.amazon.com/dp/B0B2VFJX71" target="_blank" rel="noopener noreferrer">buddha<br></br>t-shirt</a></strong>
          </button>
        </div>
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://buddha.fm" target="_blank" rel="noopener noreferrer">buddha<br></br>.fm</a></strong>
          </button>
        </div>          
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://countrymusicfy.com" target="_blank" rel="noopener noreferrer">Country<br></br>Musicfy</a></strong>
          </button>
        </div>
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://sleepchord.com" target="_blank" rel="noopener noreferrer">Sleep<br></br>Chord</a></strong>
          </button>
        </div>
      </div>
      <p></p>
      <div className="row"> 
        <div className="column-social">
          <a href="https://www.facebook.com/buddhatunes" target="_blank" rel="noopener noreferrer"><img src="/facebook-f.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
        <div className="column-social">
          <a href="https://www.instagram.com/buddhatunes" target="_blank" rel="noopener noreferrer"><img src="/instagram.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
        <div className="column-social">
          <a href="https://countrymusicfy.com" target="_blank" rel="noopener noreferrer"><img src="/user-cowboy.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
        <div className="column-social">
          <a href="https://tiktok.com/@sleepchord" target="_blank" rel="noopener noreferrer"><img src="/tiktok.svg" width="100%" className="svg-icon2" alt=""></img></a>
         </div>
        <div className="column-social">
          <a href="https://sleepchord.com/sleepchord" target="_blank" rel="noopener noreferrer"><img src="/face-sleeping.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
      </div>
    </div>
  );
}
