import { useEffect, useRef, useState } from "react";
import { Row, RowState } from "./Row";
import dictionary from "./dictionary.json";
import { Clue, clue, describeClue, violation } from "./clue";
import { Keyboard } from "./Keyboard";
import targetList from "./targets.json";
import {
  describeSeed,
  dictionarySet,
  Difficulty,
  gameName,
  pick,
  resetRng,
  seed,
  speak,
  urlParam,
} from "./util";
import { decode, encode } from "./base64";
import Tabs from "./Component/Tab";

enum GameState {
  Playing,
  Won,
  Lost,
}

interface GameProps {
  maxGuesses: number;
  hidden: boolean;
  difficulty: Difficulty;
  colorBlind: boolean;
  keyboardLayout: string;
}

const targets = targetList.slice(0, targetList.indexOf("murky") + 1); // Words no rarer than this one
const minLength = 4;
const defaultLength = 5;
const maxLength = 11;
const limitLength = (n: number) =>
  n >= minLength && n <= maxLength ? n : defaultLength;

function randomTarget(wordLength: number): string {
  const eligible = targets.filter((word) => word.length === wordLength);
  let candidate: string;
  do {
    candidate = pick(eligible);
  } while (/\*/.test(candidate));
  return candidate;
}

function getChallengeUrl(target: string): string {
  return (
    window.location.origin +
    window.location.pathname +
    "?challenge=" +
    encode(target)
  );
}

let initChallenge = "";
let challengeError = false;
try {
  initChallenge = decode(urlParam("challenge") ?? "").toLowerCase();
} catch (e) {
  console.warn(e);
  challengeError = true;
}
if (initChallenge && !dictionarySet.has(initChallenge)) {
  initChallenge = "";
  challengeError = true;
}

function parseUrlLength(): number {
  const lengthParam = urlParam("length");
  if (!lengthParam) return defaultLength;
  return limitLength(Number(lengthParam));
}

function parseUrlGameNumber(): number {
  const gameParam = urlParam("game");
  if (!gameParam) return 1;
  const gameNumber = Number(gameParam);
  return gameNumber >= 1 && gameNumber <= 1000 ? gameNumber : 1;
}

const options = [
  {
      label: <span className="switchSelectorStyle">Today</span>,
      value: "today",
      selectedBackgroundColor: "#2196F3;",
  },
  {
      label: <span className="switchSelectorStyle">Meta</span>,
      value: "random",
      selectedBackgroundColor: "#2196F3;",
  }
];

const now = new Date();
const format_date=now.getMonth()+1+'/'+ now.getDate()+'/'+now.getFullYear().toString().slice(-2);
const todaySeed =
  now.toLocaleDateString("en-US", { year: "numeric" }) +
  now.toLocaleDateString("en-US", { month: "2-digit" }) +
  now.toLocaleDateString("en-US", { day: "2-digit" });

function Game(props: GameProps) {
  const [Tabing,setTabing]=useState({Value: "today",todaySeed:"0"});
  const [gameState, setGameState] = useState(GameState.Playing);
  const [guesses, setGuesses] = useState<string[]>([]);
  const [currentGuess, setCurrentGuess] = useState<string>("");
  const [challenge, setChallenge] = useState<string>(initChallenge);
  const [wordLength, setWordLength] = useState(
    challenge ? challenge.length : parseUrlLength()
  );
  const [gameNumber, setGameNumber] = useState(parseUrlGameNumber());
  const [target, setTarget] = useState(() => {
    resetRng();
    // Skip RNG ahead to the parsed initial game number:
    for (let i = 1; i < gameNumber; i++) randomTarget(wordLength);
    return challenge || randomTarget(wordLength);
  });
  const [hint, setHint] = useState<string>(
    challengeError
      ? `Invalid challenge string, playing random game.`
      : `Make Your First Guess!`
  );
  const currentSeedParams = () =>
    `?seed=${seed}&length=${wordLength}&game=${gameNumber}`;
  useEffect(() => {
    if (seed) {
      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + currentSeedParams()
      );
    }
  }, [wordLength, gameNumber]);
  const tableRef = useRef<HTMLTableElement>(null);
  const startNextGame = () => {
    if (challenge) {
      // Clear the URL parameters:
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    setChallenge("");
    const newWordLength = limitLength(wordLength);
    setWordLength(newWordLength);
    setTarget(randomTarget(newWordLength));
    setHint("");
    setGuesses([]);
    setCurrentGuess("");
    setGameState(GameState.Playing);
    setGameNumber((x) => x + 1);
  };

  async function share(copiedHint: string, text?: string) {
    const url = seed
      ? window.location.origin + window.location.pathname + currentSeedParams()
      : getChallengeUrl(target);
    const body = url + (text ? "\n\n" + text : "");
    if (
      /android|iphone|ipad|ipod|webos/i.test(navigator.userAgent) &&
      !/firefox/i.test(navigator.userAgent)
    ) {
      try {
        await navigator.share({ text: body });
        return;
      } catch (e) {
        console.warn("navigator.share failed:", e);
      }
    }
    try {
      await navigator.clipboard.writeText(body);
      setHint(copiedHint);
      return;
    } catch (e) {
      console.warn("navigator.clipboard.writeText failed:", e);
    }
    setHint(url);
  }

  const onKey = (key: string) => {
    if (gameState !== GameState.Playing) {
      if (key === "Enter") {
        startNextGame();
      }
      return;
    }
    if (guesses.length === props.maxGuesses) return;
    if (/^[a-z]$/i.test(key)) {
      setCurrentGuess((guess) =>
        (guess + key.toLowerCase()).slice(0, wordLength)
      );
      tableRef.current?.focus();
      setHint("");
    } else if (key === "Backspace") {
      setCurrentGuess((guess) => guess.slice(0, -1));
      setHint("");
    } else if (key === "Enter") {
      if (currentGuess.length !== wordLength) {
        setHint("Too short");
        return;
      }
      if (!dictionary.includes(currentGuess)) {
        setHint("Not a valid word");
        return;
      }
      for (const g of guesses) {
        const c = clue(g, target);
        const feedback = violation(props.difficulty, c, currentGuess);
        if (feedback) {
          setHint(feedback);
          return;
        }
      }
      setGuesses((guesses) => guesses.concat([currentGuess]));
      setCurrentGuess((guess) => "");

      const gameOver = (verbed: string) =>
        `You ${verbed}! Answer is ${target.toUpperCase()}. Enter to ${
          challenge ? "play a random game" : "play again"
        }.`;

      if (currentGuess === target) {
        setHint(gameOver("won"));
        setGameState(GameState.Won);
      } else if (guesses.length + 1 === props.maxGuesses) {
        setHint(gameOver("lost"));
        setGameState(GameState.Lost);
      } else {
        setHint("");
        speak(describeClue(clue(currentGuess, target)));
      }
    }
  };
  
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (!e.ctrlKey && !e.metaKey) {
        onKey(e.key);
      }
      if (e.key === "Backspace") {
        e.preventDefault();
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [currentGuess, gameState]);

  let letterInfo = new Map<string, Clue>();
  const tableRows = Array(props.maxGuesses)
    .fill(undefined)
    .map((_, i) => {
      const guess = [...guesses, currentGuess][i] ?? "";
      const cluedLetters = clue(guess, target);
      const lockedIn = i < guesses.length;
      if (lockedIn) {
        for (const { clue, letter } of cluedLetters) {
          if (clue === undefined) break;
          const old = letterInfo.get(letter);
          if (old === undefined || clue > old) {
            letterInfo.set(letter, clue);
          }
        }
      }
      return (
        <Row
          key={i}
          wordLength={wordLength}
          rowState={
            lockedIn
              ? RowState.LockedIn
              : i === guesses.length
              ? RowState.Editing
              : RowState.Pending
          }
          cluedLetters={cluedLetters}
        />
      );
    });

    const handleChange = (e:any) =>{  
      if(e==="random"){
        setTabing({Value:e,todaySeed:format_date})
      }
      else{
        setTabing({Value:e,todaySeed:"0"})
      }
    }

    const initialSelectedIndex=options.findIndex(({value}) => value === "Today");

  return (
    <div className="Game" style={{ display: props.hidden ? "none" : "block" }}>
      <div className="Game-options">
        <label htmlFor="wordLength">Letters:</label>
        <input
          type="range"
          min={minLength}
          max={maxLength}
          id="wordLength"
          disabled={
            gameState === GameState.Playing &&
            (guesses.length > 0 || currentGuess !== "" || challenge !== "")
          }
          value={wordLength}
          onChange={(e) => {
            const length = Number(e.target.value);
            resetRng();
            setGameNumber(1);
            setGameState(GameState.Playing);
            setGuesses([]);
            setCurrentGuess("");
            setTarget(randomTarget(length));
            setWordLength(length);
            setHint(`${length} letters`);
          }}
        ></input>
        <button
          style={{ flex: "0 0 auto" }}
          disabled={gameState !== GameState.Playing || guesses.length === 0}
          onClick={() => {
            setHint(
              `Answer is ${target.toUpperCase()}. Enter to play again.`
            );
            setGameState(GameState.Lost);
            (document.activeElement as HTMLElement)?.blur();
          }}
        >
          Give Up
        </button>
      </div>
      <table
        className="Game-rows"
        tabIndex={0}
        aria-label="Table of guesses"
        ref={tableRef}
      >
        <tbody>{tableRows}</tbody>
      </table>
      <p
        role="alert"
        style={{
          userSelect: /https?:/.test(hint) ? "text" : "none",
          whiteSpace: "pre-wrap",
        }}
      >
        {hint || `\u00a0`}
      </p>
      <Keyboard
        layout={props.keyboardLayout}
        letterInfo={letterInfo}
        onKey={onKey}
      />
      {/* <div className="Game-seed"> */}
      
      {/* </div> */}
      <div className="Game-seed-info">
        <div className="Game-seed">
        <Tabs options={options} onChange={(e:any)=>handleChange(e)} initialSelectedIndex={initialSelectedIndex}/>
        </div>
        {/* {challenge
          ? "playing a challenge game"
          : seed
          ? `${describeSeed(seed)} — Length ${wordLength}, Game ${gameNumber}`
          : "Play Multiple Games Daily"} */}
          <div className="Game-seed-text">
           {Tabing.Value==="today"
          ? "Play Multiple Games Daily"
          : Tabing.Value==="random"
          ? `${Tabing.todaySeed} — length ${wordLength}, game ${gameNumber}`
          :""}
        </div>
      </div>
      <p></p>
      <div className="button">
      <div className="row buttons-container">
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://www.amazon.com/dp/B0B2VFJX71" target="_blank" rel="noopener noreferrer">buddha<br></br>t-shirt</a></strong>
          </button>
        </div>
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://buddha.fm" target="_blank" rel="noopener noreferrer">buddha<br></br>.fm</a></strong>
          </button>
        </div>          
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://countrymusicfy.com" target="_blank" rel="noopener noreferrer">Country<br></br>Musicfy</a></strong>
          </button>
        </div>
        <div className="column-merch">
          <button className="merch" type="submit">
            <strong><a href="https://sleepchord.com" target="_blank" rel="noopener noreferrer">Sleep<br></br>Chord</a></strong>
          </button>
        </div>
      </div>
      </div>
      <p>
        <button className="share-link"
          onClick={() => {
            share("Link Copied to Clipboard!");
          }}
        >
          Share Link to Your Game
        </button>{" "}
        {gameState !== GameState.Playing && (
          <button className="share-link"
            onClick={() => {
              const emoji = props.colorBlind
                ? ["⬛", "🟦", "🟧"]
                : ["⬛", "🟨", "🟩"];
              const score = gameState === GameState.Lost ? "X" : guesses.length;
              share(
                "Result Copied to Clipboard!",
                `${gameName} ${score}/${props.maxGuesses}\n` +
                  guesses
                    .map((guess) =>
                      clue(guess, target)
                        .map((c) => emoji[c.clue ?? 0])
                        .join("")
                    )
                    .join("\n")
              );
            }}
          >
            Share Emoji Results
          </button>
        )}
      </p>
      <div className="row"> 
        <div className="column-social">
          <a href="https://www.facebook.com/buddhatunes" target="_blank" rel="noopener noreferrer"><img src="/facebook-f.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
        <div className="column-social">
          <a href="https://www.instagram.com/buddhatunes" target="_blank" rel="noopener noreferrer"><img src="/instagram.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
        <div className="column-social">
          <a href="https://countrymusicfy.com" target="_blank" rel="noopener noreferrer"><img src="/user-cowboy.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
        <div className="column-social">
          <a href="https://tiktok.com/@sleepchord" target="_blank" rel="noopener noreferrer"><img src="/tiktok.svg" width="100%" className="svg-icon2" alt=""></img></a>
         </div>
        <div className="column-social">
          <a href="https://sleepchord.com" target="_blank" rel="noopener noreferrer"><img src="/face-sleeping.svg" width="100%" className="svg-icon2" alt=""></img></a>
        </div>
      </div>
      <p className="copyright">
        © 2024 <a href="https://wordl.xyz" target="_blank" rel="noopener noreferrer">WORDL LLC</a>
      </p>
    </div>
  );
}

export default Game;
